/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, Text, Button, Link as ExternalLink } from 'rebass';

import MainLayout from '../../layouts/MainLayout';
import { Hero } from '../../components/Hero';
import { Link } from 'gatsby';
import { H3, H4, P } from '../../components/Text';
import { Container, ContainerFlex } from '../../components/Container';
import { CalendarGrid } from '../../components/CalendarGrid';

const IndexPage = ({ ...rest }) => {
  return (
    <MainLayout>
      <div id="content" />
      <Container mb={[3, 4, 5]}>
        <Hero>
          Design in
          <br />
          perilous times
        </Hero>
      </Container>

      <ContainerFlex mb={[4, 4, 5]} flexWrap="wrap">
        <Box width={[1, 1 / 2]} mb={[4, 0]} pr={[0, 3, 3]}>
          <Text sx={{ fontSize: 3, fontFamily: 'body' }}>
            En ligne
            <br />
            <strong sx={{ fontWeight: 700 }}>31 Jan - 5 Fév 2021</strong>
          </Text>
        </Box>
      </ContainerFlex>

      <ContainerFlex flexDirection={'column'}>
        <Flex mb={5} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 3, 3]}>
            <P>
              Le monde tel que nous le connaissons évolue et le zeitgeist
              appelle à des moyens nouveaux et innovants de se connecter et
              d'apprendre des uns des autres. Pour la première fois de son
              histoire, la Semaine d'Interaction s'est déroulée en ligne en
              2021. Notre équipe vous a préparé une expérience virtuelle épique
              qui vous a mis en contact avec certains des esprits les plus
              brillants du design et vous a aidé à résoudre les problèmes de
              notre génération. Celle-ci est passée à l'histoire!
            </P>
          </Box>
          <Box width={[1, 1 / 2]} pl={[0, 3, 3]}>
            <P>
              Merci de vous être joints à nous pour la semaine d'interaction
              2021, d'avoir engagé des conversations sur les dangers de notre
              quotidien, ce qui nous y a mené et des solutions inspirantes pour
              drastiquement changer notre destin. Nos conférenciers inspirants
              exploreront les thèmes de la{' '}
              <strong sx={{ fontWeight: 700 }}>colère</strong>,{' '}
              <strong sx={{ fontWeight: 700 }}>l’imputabilité</strong> et{' '}
              <strong sx={{ fontWeight: 700 }}>l’action</strong>.
            </P>
          </Box>
        </Flex>
        <Box sx={{ mb: 5 }}>
          <CalendarGrid />
        </Box>
        <Box sx={{ mb: 5 }}>
          <H3>Une toute nouvelle expérience</H3>
          <P>
            Notre thème, établi avant la pandémie, apparaît aujourd'hui comme un
            présage. Nous avons conçu Interaction21 en format d'abord virtuel
            pour en faire une expérience unique et épique. Attendez-vous au même
            niveau de contenu professionnel qui vous sera livré de la manière la
            plus engageante possible. Pour en savoir plus,
            <Link to="/about/faq" sx={{ variant: 'variants.link' }}>
              parcourez notre FAQ.
            </Link>
          </P>
          <Flex sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Réseauter et interagir</H4>
              <P>
                Des tables rondes d'experts, des sessions de questions-réponses
                en direct, des ateliers interactifs, des activités de groupe en
                soirée et des événements sociaux — nous avons soigneusement
                concocté des occasions de nouer des liens.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Contenu focalisé</H4>
              <P>
                Notre flux de contenu de 8 heures continues comporte des exposés
                plus courts et des pauses cognitives tout en équilibrant le
                contenu en direct et enregistré. Vous pourrez vous immerger dans
                la conférence sans les pressions d’événemanquer.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Localisation de la programmation</H4>
              <P>
                Notre programme sera diffusé pour trois régions différentes :
                les Amériques — l'Europe, le Moyen-Orient, l'Afrique —
                l'Asie-Pacifique. Où que vous soyez dans le monde, vous pouvez
                syntoniser IxD21 depuis votre région.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Un conférence de haut calibre</H4>
              <P>
                Notre thème lie notre programmation au fil de conférences et de
                sessions présentées par des leaders mondiaux de notre industrie.
                Nous disposons d'équipes véloces de production et de
                programmation pour assurer une diffusion nette et un contenu
                d'actualité.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Divertissement et promo</H4>
              <P>
                En collaboration avec nos partenaires, nous nous assurons que
                vous puissiez aisément connecter avec eux et vous procurer des
                souvenirs physiques et numériques. Durant les pauses tout au
                long de la journée, nous présenterons aussi du contenu culturel
                et divertissant!
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Faire progresser notre profession</H4>
              <P>
                Votre soutien nous est inestimable — il permet cette plateforme.
                Nous vous promettons des talents et des leaders d'opinion
                exceptionnels. La Semaine d'Interaction vous offre une occasion
                sans précédent de participer activement à la communauté mondiale
                du design.
              </P>
            </Box>
          </Flex>
        </Box>
      </ContainerFlex>
    </MainLayout>
  );
};

export default IndexPage;
